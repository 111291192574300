import React from "react";

import {Helmet} from "react-helmet";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, ModalBody, Modal,
  Carousel, CarouselItem, CarouselIndicators
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DogGallery from "./DogGallery";

function GambitPage() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === clearances.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? clearances.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const images = [
    require("../../assets/img/dogs/gambit/reg1.jpg"),
    require("../../assets/img/dogs/gambit/reg2.jpg"),
    require("../../assets/img/dogs/gambit/reg3.jpg"),
    require("../../assets/img/dogs/gambit/reg4.jpg"),
    require("../../assets/img/dogs/gambit/reg5.jpg"),
    require("../../assets/img/dogs/gambit/reg6.jpg"),
    require("../../assets/img/dogs/gambit/stack2.jpg"),
    require("../../assets/img/dogs/gambit/agility1.jpg"),
    require("../../assets/img/dogs/gambit/agility2.jpg"),
    require("../../assets/img/dogs/gambit/agility4.jpg"),
    require("../../assets/img/dogs/gambit/agility3.jpg"),
    require("../../assets/img/dogs/gambit/agility5.jpg"),
    require("../../assets/img/dogs/gambit/disc1.jpg"),
    require("../../assets/img/dogs/gambit/disc2.jpg"),
    require("../../assets/img/dogs/gambit/disc3.jpg"),
    require("../../assets/img/dogs/gambit/dock2.jpg"),
    require("../../assets/img/dogs/gambit/dock4.jpg"),
    require("../../assets/img/dogs/gambit/dock5.jpg"),
  ];

  const clearances = [
    require("../../assets/img/dogs/gambit/clearance1.jpg"),
    require("../../assets/img/dogs/gambit/clearance2.jpg"),
    require("../../assets/img/dogs/gambit/clearance3.jpg")
  ];
  return (
    <>
      <Helmet>
        <meta name="description" content="Gambit is a black tri border collie stud born on 12/2/15. He has made multiple world teams and placed at many national events in agility." />
      </Helmet>
      <MainNavbar />
      <div className="wrapper">
        <LandingPageHeader bg="gambit" />
      </div>
      <div className="back-nav">
        <a href="../dogs"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Dogs</a>
      </div>
      <div className="wrapper">
        <div className="section section-story-overview section-dog">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="name-desc">
                <h2 className="title">Gambit</h2>
                <h3 className="subtitle">PNAC MACH ADCH-BRONZE EC's Cajun Charm in the Thieves Guild MXB MXJB CGC RA NF DM DDGE DDP</h3>
                <div>
                  Gambit is a black tri border collie born on 12/2/15. He was bredby Elisa Chavez in California and has been raised and trainedby me. He’s excelled at multiple sports and is a dream to work with.
                  He is very affectionate and loves to cuddle up to people. He has an off switch in the house but is always willing to work. Gambit unfortunately produced an epileptic puppy in his only litter and since his littermate produced an eplileptic puppy as well and he did develop a cataract, I've made the decision to no longer use him in my breeding program.
                </div>
                <div>
                  <h4 className="subtitle">Personality</h4>
                  Gambit is known to be goofy and loving. He happy squeaks for people and dogs he knows and will wiggle up to people for attention. He is the clingy, momma's boy of the house who follows me around. He does well in busy environments and performs and relaxes in them with ease. He gets along well with the majority of dogs but can be insecure with some large males after some bad experiences. He is a great puppy raiser and knows how to make shy dogs feel comfortable around him.
                </div>
                <div>
                  <h4 className="subtitle">Ability</h4>
                  Gambit is the perfect dog for any level of handler. He makes an effort to get behaviors right and will slow down to think, then speed right back up once told he's correct. He is very thoughtful with his actions and body. He never went through an awkward phase. He has always been very athletic, already jumping 22 feet off a dock at 10 months old.
                </div>
              </div>
            </Col>
            <Col className="ml-auto mr-auto align-middle" md="5">
              <div className="health-clearances">
                <Row>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/dogs/gambit/stack.jpg")}
                  />
                  <div className="small">Born on 12/2/15. 22" tall. 48lbs. AKC & ABCA registered.</div>
                </Row>

                <h3 className="title text-center">Health Clearances</h3>
                <Row>
                  <div className="sec-60">
                  <ul>
                    <li><span className="bold">Hips:</span> OFA Good</li>
                    <li><span className="bold">Elbows:</span> Normal *</li>
                    <li><span className="bold">Shoulders:</span> Normal *</li>
                    <li><span className="bold">Eyes:</span> Passing w/ cataract **</li>
                  </ul>
                  * Cleared by specialist. All x-rays available on request.<br />
                  ** Suspected not inherited
                  </div>
                  <div className="sec-40">
                  <ul>
                    <li><span className="bold">CEA</span> Clear</li>
                    <li><span className="bold">DM</span> Clear</li>
                    <li><span className="bold">SN</span> Clear</li>
                    <li><span className="bold">TNS</span> Clear</li>
                    <li><span className="bold">IGS</span> <span className="carrier">Carrier</span></li>
                    <li><span className="bold">NCL5</span> Clear</li>
                    <li><span className="bold">G/G</span> Clear</li>
                    <li><span className="bold">DH</span> Clear</li>
                    <li><span className="bold">EOD</span> Clear</li>
                  </ul>
                  </div>
                </Row>
                <div className="text-right"><br/>
                  <a onClick={() => setModal1(true)} className="btn btn-round action-btn" type="button">
                    Pedigree
                  </a>
                  <a onClick={() => setModal2(true)} className="btn btn-round action-btn" type="button">
                    Health Clearances
                  </a>
                  <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal1(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                      <h4 className="title title-up"></h4>
                    </div>
                    <ModalBody>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("../../assets/img/dogs/gambit/pedigree.jpg")}
                      ></img>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal2} toggle={() => setModal2(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal2(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                    </div>
                    <ModalBody>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={clearances}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {clearances.map(item => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={item}
                              className="text-center"
                            >
                              <img src={item} alt="" className="img-small" />
                              <div className="carousel-caption d-none d-md-block">
                              </div>
                            </CarouselItem>
                          );
                        })}
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <div className="section section-dog text-center">
        <div
          className="image-fullw section-sep-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/dogs/gambit/agility6.jpg") + ")"
          }}
        ><div className="wrapper">

        </div>
        </div>
      </div>

      <div className="wrapper">
        <Container>
          <h2 className="title text-center">Accomplishments & Training</h2>
            <div className="team">
              <Row>
                <Col md="6">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/GSCUcTibfR4" frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                  <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/gambit/podium.jpg")}
                  ></img>
                </Col>
                <Col md="6">
                  <h4 className="title first">Agility</h4>
                  <p>Gambit is in the masters/championship level in AKC, USDAA, and UKI and jumps 24 inches regular. He has a long stride and is careful not to knock bars. He excels at commitment and working at a distance. He is an amazing partner and it was easy to learn how to handle agility with him. He made his (and my) first world team on the weekend of his 2nd birthday.</p>
                  <span className="bold">WORLD TEAMS</span>
                  <ul>
                    <li>2020/2022 IFCS Win on</li>
                    <li>2020 European Open Alternate</li>
                    <li>2019 European Open Win on</li>
                    <li>2018 European Open Pick</li>
                  </ul>
                  <span className="bold">NATIONAL PODIUMS</span>
                  <ul>
                  Performance/Select
                    <li>2024 AKC National Championship 1st</li>
                    <li>2024 UKI Invitational Games 3rd</li>
                    <li>2023 UKI US Open Games 1st</li>
                    <li>2023 UKI US Open Biathlon 1st</li>
                    <li>2023 AKC National Championship 5th</li>
                    
                  Championship
                    <li>2022 UKI US Open Biathlon 1st</li>
                    <li>2022 UKI US Open Masters Series 1st</li>
                    <li>2022 UKI US Open National Final 1st</li>
                    <li>2022 UKI US Open Games Challenge 1st</li>
                    <li>2022 AKC National Championship 4th</li>
                    <li>2021 UKI US Open Biathlon 1st</li>
                    <li>2021 UKI US Open Masters Series 2nd</li>
                    <li>2021 USDAA Cynosport Grand Prix 2nd</li>
                    <li>2021 AKC National Championship 3rd</li>
                    <li>2019 USDAA Cynosport Biathlon 1st</li>
                    <li>2019 UKI US Open Biathlon 1st</li>
                    <li>2018 USDAA Cynosport Team Standard & Jumpers 2nd</li>
                  </ul>
                  <span className="bold">REGIONAL PODIUMS</span>
                  <ul>
                  Performance/Select
                    <li>2024 UKI Southeast Cup 1st</li>
                    <li>2023 USDAA Southeast Biathlon 1st</li>
                    <li>2023 USDAA Southeast Team 1st</li>
                    <li>2023 USDAA Southeast Steeplechase 1st</li>

                  Championship
                    <li>2020 UKI Southeast Cup Biathlon 3rd</li>
                    <li>2019 UKI Classic Biathlon 1st</li>
                    <li>2019 UKI Classic Pentathlon 2nd</li>
                    <li>2019 UKI Classic Speedstakes 2nd</li>
                    <li>2019 USDAA Southeast Biathlon 2nd</li>
                    <li>2019 USDAA Southeast Team 3rd</li>
                    <li>2019 USDAA Mid-Atlantic Biathlon 3rd</li>
                    <li>2018 USDAA Pacific Northwest Steeplechase 1st</li>
                    <li>2018 USDAA Pacific Northwest Biathlon 1st</li>
                    <li>2018 USDAA Southwest Steeplechase 3rd</li>
                    <li>2018 USDAA Wild West Biathlon 1st</li>
                    <li>2018 USDAA Wild West Steeplechase 1st</li>
                    <li>2017 USDAA Western Steeplechase 3rd</li>
                  </ul>
                  <a href="https://www.youtube.com/channel/UCsajXTgDubEBcs9hS_afOJw/videos" target="_blank" className="btn btn-round action-btn" color="info" type="button">
                    <i className="fab fa-youtube"></i> View more videos on youtube
                  </a>
                </Col>
              </Row>
              <div className="spacer"></div>
              <Row>
                <Col md="4">
                  <div className="">
                    <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/gambit/dock5.jpg")}
                    ></img>
                    <h4 className="title">Dock Diving</h4>
                    <p className="description">
                      Personal best: 26 feet 5 inches<br />
                      Air retrieve: 21 feet<br />
                      #1 Border Collie in AR 2019<br />
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/gambit/disc1.jpg")}
                    ></img>
                    <h4 className="title">Disc</h4>
                    <p className="description">
                      Gambit competes in UpDog and local Toss and Fetch.<br/>
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/gambit/flyball1.jpg")}
                    ></img>
                    <h4 className="title">Other</h4>
                    <p className="description">
                      Flyball: Starting to trial, PB 4.2 <br />
                      Rally: Novice title achieved at 7 months.<br/>
                      Barn Hunt: Novice title achieved at 10 months.<br/>
                      Herding: Basic training. He has great instinct and his instructors have been enthusiastic about his potential.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section-dog">
          <div
              className="image-fullw section-gallery-bg"
              style={{
                backgroundImage: "url(" + require("../../assets/img/abstract-bg.jpg") + ")"
              }}
            >
              <h2 className="title text-center">Photo Gallery</h2>
              <DogGallery images={images} />
              <div className="text-center">
                <a href="https://www.facebook.com/gambit.bc/" target="_blank" className="btn btn-round" color="info" type="button">
                  View more photos on Gambit's Facebook Page
                </a>
              </div>
            </div>
        </div>
    </>
  );
}

export default GambitPage;
