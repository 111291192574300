import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";


function Index() {
  const video = require("../assets/img/endgame-home-sm-3.mp4");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <MainNavbar />
      <div className="home-page wrapper">
        <video id="background-video" autoPlay loop muted poster="">
          <source src={video} type="video/mp4"></source>
        </video>
        <div className="main">

        </div>
      </div>
    </>
  );
}

export default Index;
