/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Container>
          <nav>
            <ul>
              <li>
              <a href="https://www.facebook.com/endgamesportdogs"><img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/facebook3.png")}
                  />
                  </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, {" "}
            <a
              href="mailto:adriana@endgamedogs.com"
              target="_blank"
            >
              Endgame Sport Dogs, Adriana Nottestad
            </a>.
          </div>
        </Container>
      </footer>
    </>
  );
}

export default DefaultFooter;
