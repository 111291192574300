import React, { Component } from 'react';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css'; // This only needs to be imported once in your app
import {
  Row,
  Col,
  Container
} from "reactstrap";

const images = [
  '//placekitten.com/1500/500',
  '//placekitten.com/4000/3000',
  '//placekitten.com/800/1200',
  '//placekitten.com/1500/1500',
];

export default class DogGallery extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  openLightbox(index) {
    this.setState({ photoIndex: index });
    this.setState({ isOpen: true });
  }

  render() {
    let { photoIndex, isOpen } = this.state;
    return (
      <div>
        <Container>
          <Row>
            {this.props.images.map((item, index) =>
              <Col md="2" className="padding-0 gallery-image">
                <img src={item} key={index} onClick={() => this.openLightbox(index)} />
              </Col>
            )}
          </Row>
        </Container>

        {isOpen && (
          <Lightbox
            mainSrc={this.props.images[photoIndex]}
            nextSrc={this.props.images[(photoIndex + 1) % this.props.images.length]}
            prevSrc={this.props.images[(photoIndex + this.props.images.length - 1) % this.props.images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + this.props.images.length - 1) % this.props.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % this.props.images.length,
              })
            }
          />
        )}
      </div>
    );
  }
}