import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useRouteMatch
} from "react-router-dom";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import GambitPage from "./GambitPage";
import CirillaPage from "./CirillaPage";
import FigmentPage from "./FigmentPage";
import VenusPage from "./VenusPage";
import NimonaPage from "./NimonaPage";
import ChibiusaPage from "./ChibiusaPage";
import SqueezePage from "./SqueezePage";

function DogsPage() {
  let { path, url } = useRouteMatch();
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <MainNavbar />
          <div className="wrapper">
            <LandingPageHeader bg="header6" />
            <div className="section">
              <Container>
                <h2 className="title">Breeding Dogs</h2>
                <Row>
                  <Col md="4"><a href="/dogs/venus" className="dogs-link">
                    <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/profile-venus2.jpg")}
                  ></img>
                    <div className="dogs-name"><span>Venus</span></div>
                  </a>
                  </Col>
                  <Col md="4"><a href="/dogs/nimona" className="dogs-link">
                    <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/profile-nimona.jpg")}
                  ></img>
                    <div className="dogs-name"><span>Nimona</span></div>
                  </a>
                  </Col>
                </Row>
                <h2 className="title pt-5">Co-owns</h2>
                <Row>
                  <Col md="4"><a href="/dogs/chibiusa" className="dogs-link">
                    <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/profile-chibi.jpg")}
                    ></img>
                    <div className="dogs-name"><span>ChibiUSA</span></div>
                  </a>
                  </Col>
                  <Col md="4"><a href="/dogs/squeeze" className="dogs-link">
                    <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/profile-squeeze.jpg")}
                    ></img>
                    <div className="dogs-name"><span>Squeeze</span></div>
                  </a>
                  </Col>
                </Row>
                <h2 className="title pt-5">Retired/Non-breeding</h2>
                <Row>
                  <Col md="4"><a href="/dogs/gambit" className="dogs-link">
                    <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/profile-gambit2.jpg")}
                    ></img>
                    <div className="dogs-name"><span>Gambit</span></div>
                  </a>
                  </Col>
                  <Col md="4"><a href="/dogs/cirilla" className="dogs-link">
                    <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/profile-ciri2.jpg")}
                  ></img>
                    <div className="dogs-name"><span>Cirilla</span></div>
                  </a>
                  </Col>                
                </Row>
              </Container>
            </div>
            <DefaultFooter />
          </div>
        </Route>
        <Route path={`${path}/gambit`}>
          <GambitPage />
        </Route>
        <Route path={`${path}/cirilla`}>
          <CirillaPage />
        </Route>
        <Route path={`${path}/venus`}>
          <VenusPage />
        </Route>
        <Route path={`${path}/figment`}>
          <FigmentPage />
        </Route>
        <Route path={`${path}/nimona`}>
          <NimonaPage />
        </Route>
        <Route path={`${path}/squeeze`}>
          <SqueezePage />
        </Route>
        <Route path={`${path}/chibiusa`}>
          <ChibiusaPage />
        </Route>
      </Switch>
    </>
  );
}

export default DogsPage;
