import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, ModalBody, Modal
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DogGallery from "./DogGallery";

function FigmentPage() {
  const [modal1, pedigreeModal] = React.useState(false);
  const [modal2, healthModal] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const images = [
    require("../../assets/img/dogs/gambit/reg1.jpg"),
    require("../../assets/img/dogs/gambit/reg2.jpg"),
    require("../../assets/img/dogs/gambit/reg3.jpg"),
    require("../../assets/img/dogs/gambit/reg4.jpg"),
    require("../../assets/img/dogs/gambit/reg5.jpg"),
    require("../../assets/img/dogs/gambit/reg6.jpg"),
    require("../../assets/img/dogs/gambit/stack2.jpg"),
    require("../../assets/img/dogs/gambit/agility1.jpg"),
    require("../../assets/img/dogs/gambit/agility2.jpg"),
    require("../../assets/img/dogs/gambit/agility4.jpg"),
    require("../../assets/img/dogs/gambit/agility3.jpg"),
    require("../../assets/img/dogs/gambit/agility5.jpg"),
    require("../../assets/img/dogs/gambit/disc1.jpg"),
    require("../../assets/img/dogs/gambit/disc2.jpg"),
    require("../../assets/img/dogs/gambit/disc3.jpg"),
    require("../../assets/img/dogs/gambit/dock2.jpg"),
    require("../../assets/img/dogs/gambit/dock4.jpg"),
    require("../../assets/img/dogs/gambit/dock5.jpg"),
  ];
  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <LandingPageHeader bg="figment" />
      </div>
      <div className="back-nav">
        <a href="../dogs"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Dogs</a>
      </div>
      <div className="wrapper">
        <div className="section section-story-overview section-dog">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="name-desc">
                <h2 className="title">Figment</h2>
                <h3 className="subtitle">Crest-Vue One Little Spark TKA</h3>
                <div>
                  Figment is a sable powderpuff Chinese Crested born on 4/4/20. He was bredby Karen Fischer-Smith in Arizona and has been raised and trained by me. He's a boisterious, energetic little guy with hopefully a bright future in sports! Fig was diagnosed with bilateral luxating patella late 2020 and will not be bred.
                </div>
                <div>
                  <h4 className="subtitle">Personality</h4>
                  Figment is very playful and has a fairly good work ethic for a small dog. He is a little sensitive in new environments but is a spitfire once he gets warmed up. He loves people and kids, and he thinks all dogs are his friends, especially large boy border collies. 
                </div>
                <div>
                  <h4 className="subtitle">Ability</h4>
                  Coming soon
                </div>
              </div>
            </Col>
            <Col className="ml-auto mr-auto align-middle" md="5">
              <div className="health-clearances">
                <Row>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/dogs/figment/stack.jpg")}
                  />
                  <div className="small">Born on 4/4/20. 13" tall. 11lbs. AKC registered.</div>
                </Row>

                <h3 className="title text-center">Health Clearances</h3>
                <Row>
                  <div className="sec-60">
                  <ul>
                    <li><span className="bold">Knees:</span> Grade I/Grade II (Operated)</li>
                    <li><span className="bold">Heart:</span> N/A</li>
                    <li><span className="bold">Hips:</span> N/A</li>
                    <li><span className="bold">Eyes:</span> N/A</li>
                  </ul>
                  </div>
                  <div className="sec-40">
                  <ul>
                    <li><span className="bold">PRA</span> Clear</li>
                    <li><span className="bold">PRCD</span> Clear</li>
                    <li><span className="bold">PLL</span> Clear</li>
                    <li><span className="bold">NCL</span> Clear</li>
                    <li><span className="bold">CMSD</span> Clear</li>
                    <li><span className="bold">IVDD</span> Clear</li>
                  </ul>
                  </div>
                </Row>
                <div className="text-right"><br/>
                  <a href="http://embk.me/crestvueonelittlespark?utm_campaign=cns_ref_dog_pub_profile&utm_medium=other&utm_source=embark" target="_blank" className="btn btn-round action-btn" type="button">
                    Embark Results Page
                  </a>
                  <Modal isOpen={modal1} toggle={() => pedigreeModal(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => pedigreeModal(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                      <h4 className="title title-up"></h4>
                    </div>
                    <ModalBody>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("../../assets/img/dogs/gambit/pedigree.jpg")}
                      ></img>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <div className="section section-dog text-center">
        <div
          className="image-fullw section-sep-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/dogs/figment/reg1.jpg") + ")"
          }}
        ><div className="wrapper">

        </div>
        </div>
      </div>

      <div className="wrapper">
        <Container>
          <h2 className="title text-center">Accomplishments & Training</h2>
            <div className="team">
              <Row>
                <Col md="6">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/WQS1fHE5yeU" frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </Col>
                <Col md="6">
                  <h4 className="title first">Agility</h4>
                  <p>Fig has started trialing in agility and will learn flyball this winter.</p>
                </Col>
              </Row>
              <div className="spacer"></div>
            </div>
          </Container>
        </div>
    </>
  );
}

export default FigmentPage;
