import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, ModalBody, Modal,
  Carousel, CarouselItem, CarouselIndicators
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";
import DogGallery from "./DogGallery";

function CirillaPage() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === clearances.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? clearances.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const images = [
    require("../../assets/img/dogs/cirilla/agility1.jpg"),
    require("../../assets/img/dogs/cirilla/agility2.jpg"),
    require("../../assets/img/dogs/cirilla/agility3.jpg"),
    require("../../assets/img/dogs/cirilla/agility5.jpg"),
    require("../../assets/img/dogs/cirilla/disc2.jpg"),
    require("../../assets/img/dogs/cirilla/disc3.jpg"),
    require("../../assets/img/dogs/cirilla/disc4.jpg"),
    require("../../assets/img/dogs/cirilla/disc5.jpg"),
    require("../../assets/img/dogs/cirilla/dock1.jpg"),
    require("../../assets/img/dogs/cirilla/dock2.jpg"),
    require("../../assets/img/dogs/cirilla/flyball1.jpg"),
    require("../../assets/img/dogs/cirilla/reg1.jpg"),
    require("../../assets/img/dogs/cirilla/reg3.jpg"),
    require("../../assets/img/dogs/cirilla/reg5.jpg"),
    require("../../assets/img/dogs/cirilla/reg10.jpg"),
    require("../../assets/img/dogs/cirilla/reg12.jpg"),
    require("../../assets/img/dogs/cirilla/reg13.jpg"),
    require("../../assets/img/dogs/cirilla/reg9.jpg")
  ];

  const clearances = [
    require("../../assets/img/dogs/cirilla/clearance1.jpg"),
    require("../../assets/img/dogs/cirilla/clearance2.jpg"),
    require("../../assets/img/dogs/cirilla/clearance3.jpg"),
    require("../../assets/img/dogs/cirilla/clearance4.jpg"),
    require("../../assets/img/dogs/cirilla/clearance5.jpg"),
    require("../../assets/img/dogs/cirilla/clearance6.jpg")
  ];
  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <LandingPageHeader bg="cirilla" />
      </div>
      <div className="back-nav">
        <a href="../dogs"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Dogs</a>
      </div>
      <div className="wrapper">
        <div className="section section-story-overview section-dog">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="name-desc">
                <h2 className="title">Cirilla</h2>
                <h3 className="subtitle">Infinity Runners Something Special AX AXJ DM</h3>
                <div>
                  Ciri is a red merle border collie born on 9/16/18. She was bred by Zuza Felczak in Poland and has been raised and trained by me. She is a very excitable dog with amazing physical abilities and high drive. Ciri was unfortunately never able to get pregnant so she will be spayed and continue her sports career.
                </div>
                <div>
                  <h4 className="subtitle">Personality</h4>
                  Cirilla is the energizer bunny of the house. She is often running around and always looking for an opportunity to play. She enjoys playing with other dogs that she knows well and is indifferent to other dogs, even when they approach her while working. She loves everyone she meets and enjoys trying to jump into their arms whether or not they are expecting it. Ciri has both an independent and cuddly nature. She is fine on her own, but also enjoys curling up at my feet every night.
                </div>
                <div>
                  <h4 className="subtitle">Ability</h4>
                  Ciri puts 110% effort into everything she does. Her first jumps off of the dock were over 20 feet. She has a lot of power and speed and can be difficult to reign in, but she is amazing once control is added. She works equally as well for both food and toys. She can work in any environment, no matter how busy or noisy. She enjoys herding the other house dogs, but she has not had any interest in stock. She is very high drive and high energy. 
                </div>
              </div>
            </Col>
            <Col className="ml-auto mr-auto align-middle" md="5">
              <div className="health-clearances">
                <Row>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/dogs/cirilla/stack5.jpg")}
                  />
                  <div className="small">Born on 9/16/18. 19" tall. 32lbs. AKC & FCI registered.</div>
                </Row>

                <h3 className="title text-center">Health Clearances</h3>
                <Row>
                  <div className="sec-60">
                  <ul>
                    <li><span className="bold">Hips:</span> OFA Good, PennHip DI .34</li>
                    <li><span className="bold">Elbows:</span> Normal *</li>
                    <li><span className="bold">Shoulders:</span> OFA Normal</li>
                    <li><span className="bold">Eyes:</span> Normal (2020)</li>
                  </ul>
                  * OFA Prelim
                  </div>
                  <div className="sec-40">
                  <ul>
                    <li><span className="bold">CEA</span> Clear</li>
                    <li><span className="bold">DM</span> Clear</li>
                    <li><span className="bold">SN</span> Clear</li>
                    <li><span className="bold">TNS</span> Clear</li>
                    <li><span className="bold">IGS</span> Clear</li>
                    <li><span className="bold">NCL5</span> Clear</li>
                    <li><span className="bold">G/G</span> Clear</li>
                    <li><span className="bold">DH</span> Clear</li>
                    <li><span className="bold">EOD</span> <span className="carrier">Carrier</span></li>
                  </ul>
                  </div>
                </Row>
                <div className="text-center"><br/>
                  <a onClick={() => setModal1(true)} className="btn btn-round action-btn" type="button">
                    Pedigree
                  </a>
                  <a onClick={() => setModal2(true)} target="_blank" className="btn btn-round action-btn" type="button">
                    Health Clearances
                  </a>
                  <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal1(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                      <h4 className="title title-up"></h4>
                    </div>
                    <ModalBody>
                      <img
                        alt="..."
                        className="img-fluid"
                        src={require("../../assets/img/dogs/cirilla/pedigree.jpg")}
                      ></img>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal2} toggle={() => setModal2(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal2(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                    </div>
                    <ModalBody>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={clearances}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {clearances.map(item => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={item}
                              className="text-center"
                            >
                              <img src={item} alt="" className="img-small" />
                              <div className="carousel-caption d-none d-md-block">
                              </div>
                            </CarouselItem>
                          );
                        })}
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <div className="section section-dog text-center">
        <div
          className="image-fullw section-sep-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/dogs/cirilla/agility6-header.jpg") + ")"
          }}
        ><div className="wrapper">

        </div>
        </div>
      </div>

      <div className="wrapper">
        <Container>
          <h2 className="title text-center">Accomplishments & Training</h2>
            <div className="team">
              <Row>
                <Col md="6">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/29hWdOdyDfU" frameBorder="0"
                          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen></iframe>
                </Col>
                <Col md="6">
                  <h4 className="title first">Agility</h4>
                  <p>Cirilla has been trialing in agility for a few months. Unfortunately, COVID-19 prevented her from attending some planned events, but she made it into Excellent Jumpers and Standard in AKC by her 2nd birthday. Her first excellent jumpers q was 7.54 YPS and she has had multiple runs over 7 YPS since. She ended up 5th in masters series in a very large 20" class at the 2021 US Open.</p>
                  <a href="https://www.youtube.com/channel/UCsajXTgDubEBcs9hS_afOJw/videos" target="_blank" className="btn btn-round action-btn" color="info" type="button">
                    <i className="fab fa-youtube"></i> View more videos on youtube
                  </a>
                </Col>
              </Row>
              <div className="spacer"></div>
              <Row>
                <Col md="4">
                  <div className="">
                    <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/cirilla/dock3.jpg")}
                    ></img>
                    <h4 className="title">Dock Diving</h4>
                    <p className="description">
                      Personal best: 25 feet 3 inches<br />
                      Hydrodash personal best: 14.768<br />
                      First jump off a dock: 22 feet 6 inches <br />
                      2020 Atlantic Coast Master Hydrodash Winner
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/cirilla/disc4.jpg")}
                    ></img>
                    <h4 className="title">Disc</h4>
                    <p className="description">
                      Cirilla competes in UpDog and local Toss and Fetch.<br/>
                    </p>
                  </div>
                </Col>
                <Col md="4">
                  <div>
                    <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/cirilla/flyball2.jpg")}
                    ></img>
                    <h4 className="title">Flyball</h4>
                    <p className="description">
                      PB: 3.877, AVG: 3.9<br />
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section-dog">
          <div
              className="image-fullw section-gallery-bg"
              style={{
                backgroundImage: "url(" + require("../../assets/img/abstract-bg.jpg") + ")"
              }}
            >
              <h2 className="title text-center">Photo Gallery</h2>
              <DogGallery images={images} />
              <div className="text-center">
                <a href="https://www.facebook.com/cirilla.bc/" target="_blank" className="btn btn-round" color="info" type="button">
                  View more photos on Cirilla's Facebook Page
                </a>
              </div>
            </div>
        </div>
    </>
  );
}

export default CirillaPage;
