import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, ModalBody, Modal, 
  Carousel, CarouselItem, CarouselIndicators
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";
import DogGallery from "./DogGallery";

function SqueezePage() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === clearances.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? clearances.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const images = [
    require("../../assets/img/dogs/venus/reg1.jpg"),
    require("../../assets/img/dogs/venus/reg2.jpg"),
    require("../../assets/img/dogs/venus/reg3.jpg"),
    require("../../assets/img/dogs/venus/reg4.jpg"),
    require("../../assets/img/dogs/venus/reg5.jpg"),
    require("../../assets/img/dogs/venus/reg6.jpg"),
    require("../../assets/img/dogs/venus/reg7.jpg"),
    require("../../assets/img/dogs/venus/reg8.jpg"),
    require("../../assets/img/dogs/venus/reg9.jpg"),
    require("../../assets/img/dogs/venus/reg10.jpg"),
    require("../../assets/img/dogs/venus/reg11.jpg"),
    require("../../assets/img/dogs/venus/reg12.jpg")
  ];

  const clearances = [
    require("../../assets/img/dogs/squeeze/clearance1.jpg"),
    require("../../assets/img/dogs/squeeze/clearance2.jpg"),
    require("../../assets/img/dogs/squeeze/clearance3.jpg"),
  ];
  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <LandingPageHeader bg="squeeze" />
      </div>
      <div className="back-nav">
        <a href="../dogs"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Dogs</a>
      </div>
      <div className="wrapper">
        <div className="section section-story-overview section-dog">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="name-desc">
                <h2 className="title">Squeeze</h2>
                <h3 className="subtitle">Endgame's Fresh Squeeze</h3>
                <div>
                  Squeeze is a blue merle rough coat border collie from the Venus x Rip litter. She will live and train with Nicole Fischer and will remain part of the Endgame breeding program.
                </div>
                <div>
                  <h4 className="subtitle">Personality</h4>
                  Coming soon
                </div>
                <div>
                  <h4 className="subtitle">Ability</h4>
                  Coming soon
                </div>
              </div>
            </Col>
            <Col className="ml-auto mr-auto align-middle" md="5">
              <div className="health-clearances">
                <Row>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/dogs/squeeze/squeeze-stack.jpg")}
                  />
                  <div className="small">Born on 05/13/23.</div>
                </Row>

                <h3 className="title text-center">Health Clearances</h3>
                <Row>
                  <div className="sec-60">
                  <ul>
                    <li><span className="bold">Hips:</span> TBD</li>
                    <li><span className="bold">Elbows:</span> TBD</li>
                    <li><span className="bold">Shoulders:</span> TBD</li>
                    <li><span className="bold">Eyes:</span> Normal (2023)</li>
                    <li><span className="bold">Knees:</span> TBD</li>
                    <li><span className="bold">Hearing:</span> BAER Normal</li>
                  </ul>
                  </div>
                  <div className="sec-40">
                  <ul>
                    <li><span className="bold">CEA</span> Clear</li>
                    <li><span className="bold">DM</span> Clear</li>
                    <li><span className="bold">SN</span> Clear</li>
                    <li><span className="bold">TNS</span> Clear</li>
                    <li><span className="bold">IGS</span> Clear</li>
                    <li><span className="bold">NCL5</span> Clear</li>
                    <li><span className="bold">G/G</span> Clear</li>
                    <li><span className="bold">DH</span> Clear</li>
                    <li><span className="bold">EOD</span> Clear</li>
                  </ul>
                </div>
                </Row>
                <div className="text-center"><br/>
                  <a onClick={() => setModal1(true)} className="btn btn-round action-btn" type="button">
                    Pedigree
                  </a>
                  <a onClick={() => setModal2(true)} className="btn btn-round action-btn" type="button">
                    Health Clearances
                  </a>
                  <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal1(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                      <h4 className="title title-up"></h4>
                    </div>
                    <ModalBody>
                      <img
                        alt="..."
                        src={require("../../assets/img/dogs/litters/vr2023/pedigree.jpg")}
                      ></img>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal2} toggle={() => setModal2(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal2(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                    </div>
                    <ModalBody>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={clearances}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {clearances.map(item => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={item}
                              className="text-center"
                            >
                              <img src={item} alt="" className="img-small" />
                              <div className="carousel-caption d-none d-md-block">
                              </div>
                            </CarouselItem>
                          );
                        })}
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SqueezePage;
