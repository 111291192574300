import React from "react";

import header1 from "../../assets/img/header-bg1.jpg";
import header2 from "../../assets/img/header-bg2.jpg";
import header3 from "../../assets/img/header-bg3.jpg";
import header4 from "../../assets/img/header-bg4.jpg";
import header5 from "../../assets/img/header-bg5.jpg";
import header6 from "../../assets/img/header-bg6.jpg";
import gambit from "../../assets/img/header-gambit.jpg";
import cirilla from "../../assets/img/header-cirilla.jpg";
import figment from "../../assets/img/header-figment.jpg";
import venus from "../../assets/img/header-venus.jpg";
import nimona from "../../assets/img/header-nimona.jpg";
import chibiusa from "../../assets/img/header-chibiusa.jpg";
import squeeze from "../../assets/img/header-squeeze.jpg";
import vh2022 from "../../assets/img/dogs/litters/vh2022/header2.jpg";
import vr2023 from "../../assets/img/dogs/litters/vr2023/header.jpg";
import nj2025 from "../../assets/img/header-nj2025.jpg";
import vs2024 from "../../assets/img/header-vs2024b.jpg";
import vd2024 from "../../assets/img/header-vd2024.jpg";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

class LandingPageHeader extends React.Component {
  render() {
    let pageHeader = React.createRef();
    const components = {
      header1: header1,
      header2: header2,
      header3: header3,
      header4: header4,
      header5: header5,
      header6: header6,
      gambit: gambit,
      cirilla: cirilla,
      figment: figment,
      venus: venus,
      nimona: nimona,
      chibiusa: chibiusa,
      squeeze: squeeze,
      vh2022: vh2022,
      vr2023: vr2023,
      nj2025: nj2025,
    };
    const headerUrl = components[this.props.bg];
    console.log(this.props.bg);

    const sectionStyle = {
      backgroundImage: `url(${headerUrl})`
    };

    return (
        <>
          <div className="page-header page-header-small no-overlay">
            <div
                className="page-header-image"
                style={sectionStyle}
                ref={pageHeader}
            ></div>
            <div className="content-center">
            </div>
          </div>
        </>
    );
  };
}

export default LandingPageHeader;
