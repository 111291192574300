import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, ModalBody, Modal, 
  Carousel, CarouselItem, CarouselIndicators
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";
import DogGallery from "./DogGallery";

function VenusPage() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === clearances.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? clearances.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const images = [
    require("../../assets/img/dogs/venus/reg1.jpg"),
    require("../../assets/img/dogs/venus/reg2.jpg"),
    require("../../assets/img/dogs/venus/reg3.jpg"),
    require("../../assets/img/dogs/venus/reg4.jpg"),
    require("../../assets/img/dogs/venus/reg5.jpg"),
    require("../../assets/img/dogs/venus/reg6.jpg"),
    require("../../assets/img/dogs/venus/reg7.jpg"),
    require("../../assets/img/dogs/venus/reg8.jpg"),
    require("../../assets/img/dogs/venus/reg9.jpg"),
    require("../../assets/img/dogs/venus/reg10.jpg"),
    require("../../assets/img/dogs/venus/reg11.jpg"),
    require("../../assets/img/dogs/venus/reg12.jpg"),
    require("../../assets/img/dogs/venus/reg13.jpg"),
    require("../../assets/img/dogs/venus/reg14.jpg"),
    require("../../assets/img/dogs/venus/reg15.jpg"),
    require("../../assets/img/dogs/venus/reg16.jpg"),
    require("../../assets/img/dogs/venus/reg17.jpg"),
    require("../../assets/img/dogs/venus/reg18.jpg")
  ];

  const clearances = [
    require("../../assets/img/dogs/venus/clearance1.jpeg"),
    require("../../assets/img/dogs/venus/clearance2.png"),
    require("../../assets/img/dogs/venus/clearance3.png"),
    require("../../assets/img/dogs/venus/clearance6.jpg"),
    require("../../assets/img/dogs/venus/clearance4.jpg"),
    require("../../assets/img/dogs/venus/clearance5.jpg"),
    require("../../assets/img/dogs/venus/clearance7.jpg"),
    require("../../assets/img/dogs/venus/clearance8.jpg")
  ];
  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <LandingPageHeader bg="venus" />
      </div>
      <div className="back-nav">
        <a href="../dogs"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Dogs</a>
      </div>
      <div className="wrapper">
        <div className="section section-story-overview section-dog">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="name-desc">
                <h2 className="title">Venus</h2>
                <h3 className="subtitle">Rafter W Venus FMCH DM HSAS HSBS BCAT RATN</h3>
                <div>
                  Venus is a black and white smooth coat border collie who was bred by Cyndy Douan at Rafter W in Georgia. She was purchased at 15 months from her previous owner. She came started on sheep and trials in USBCHA and AKC herding. She is very friendly and affectionate. She came into a house full of dogs with ease and has won everyone over since.
                </div>
                <div>
                  <h4 className="subtitle">Personality</h4>
                  Venus has the ideal tempermant that I seek out in a border collie. She is very much a "boy dog" goofy personality with the high drive and serious work ethic that typically comes with females. She is friendly with everyone and loves cuddling. She is biddable and always willing to do what is asked of her. She handles busy, chaotic environments without batting an eye and is very enthusiastic to work. She can work for hours but is equally happy being belly-up on the couch all day. She works for food and toys equally despite not having any play foundations as a pup.
                </div>
                <div>
                  <h4 className="subtitle">Ability</h4>
                  V has amazing potential in herding and dock diving and is currently competing in flyball. She took high in trial and high combined her first AKC trial. She is competing in the pro novice level in USBCHA. Her first dock diving event was a pb of 23ft and hshe currently jumps in the 26ft range. She debuted in flyball March 2022 and ran 3.8 - 3.9s her first tournament with a personal best that weekend of 3.805 and her first race being 3.877.
                </div>
              </div>
            </Col>
            <Col className="ml-auto mr-auto align-middle" md="5">
              <div className="health-clearances">
                <Row>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/dogs/venus/venus-stack.jpg")}
                  />
                  <div className="small">Born on 11/12/19. 18" tall. 30lbs. AKC & ABCA registered.</div>
                </Row>

                <h3 className="title text-center">Health Clearances</h3>
                <Row>
                  <div className="sec-60">
                  <ul>
                    <li><span className="bold">Hips:</span> OFA Good*, PennHip .32/.35</li>
                    <li><span className="bold">Elbows:</span> Normal*</li>
                    <li><span className="bold">Shoulders:</span> OFA Normal</li>
                    <li><span className="bold">Eyes:</span> Clear w/ PSTO (2022)</li>
                    <li><span className="bold">Heart:</span> OFA Advanced Cardiac Clear</li>
                    <li><span className="bold">Spine:</span> LTV Free</li>
                    <li><span className="bold">CHIC #:</span> 178944</li>
                  </ul>
                  * OFA Prelim
                  </div>
                  <div className="sec-40">
                  <ul>
                    <li><span className="bold">CEA</span> Clear</li>
                    <li><span className="bold">DM</span> Clear</li>
                    <li><span className="bold">SN</span> Clear</li>
                    <li><span className="bold">TNS</span> Clear</li>
                    <li><span className="bold">IGS</span> Clear</li>
                    <li><span className="bold">NCL5</span> Clear</li>
                    <li><span className="bold">G/G</span> Clear</li>
                    <li><span className="bold">DH</span> Clear</li>
                    <li><span className="bold">EOD</span> Clear</li>
                  </ul>
                  </div>
                </Row>
                <div className="text-center"><br/>
                  <a onClick={() => setModal1(true)} className="btn btn-round action-btn" type="button">
                    Pedigree
                  </a>
                  <a onClick={() => setModal2(true)} className="btn btn-round action-btn" type="button">
                    Health Clearances
                  </a>
                  <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal1(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                      <h4 className="title title-up"></h4>
                    </div>
                    <ModalBody>
                      <img
                        alt="..."
                        src={require("../../assets/img/dogs/venus/pedigree.jpg")}
                      ></img>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal2} toggle={() => setModal2(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal2(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                    </div>
                    <ModalBody>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={clearances}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {clearances.map(item => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={item}
                              className="text-center"
                            >
                              <img src={item} alt="" className="img-small" />
                              <div className="carousel-caption d-none d-md-block">
                              </div>
                            </CarouselItem>
                          );
                        })}
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <div className="section section-dog text-center">
        <div
          className="image-fullw section-sep-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/dogs/venus/header-v2.jpg") + ")"
          }}
        ><div className="wrapper">

        </div>
        </div>
      </div>

      <div className="wrapper">
        <Container>
          <h2 className="title text-center">Accomplishments & Training</h2>
            <div className="team">
              <Row>
                <Col md="6" className="mb-5">
                  <iframe width="100%" height="315" src="https://www.youtube.com/embed/videoseries?si=GV-kfEHFHNrsgREr&amp;list=PLta6k7s4hvnkVVH_22PYMxIzuBfPVVuWf" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                  <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/venus/podium.jpg")}
                  ></img>
                </Col>
                <Col md="6">
                  <h4 className="title first">Main Sports</h4>
                  <p>Venus has done well at local herding trials. Videos of her herding can be found in the playlist to the left. She primarily competes in flyball averages a 3.9 in the pack and 3.8s in start. She will be focusing on flyball and dock diving for the upcoming year with some extra sports on the side. She loves to watch small critters so she will be trying some barn hunt trials in 2024.</p>
                </Col>
              </Row>
              <Row>
                <Col md="4" className="mb-5">
                  <div className="">
                    <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("../../assets/img/dogs/venus/herding1.jpg")}
                    ></img>
                    <h4 className="title">Herding</h4>
                    <p className="description">
                     USBCHA: Pro-Novice<br />
                     High in Trial and High Combined AKC winner
                    </p>
                  </div>
                </Col>
                <Col md="4" className="mb-5">
                  <div>
                    <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("../../assets/img/dogs/venus/flyball1.jpg")}
                    ></img>
                    <h4 className="title">Flyball</h4>
                    <p className="description">
                      Personal best: 3.764<br />
                      3.8 average
                    </p>
                  </div>
                </Col>
                <Col md="4" className="mb-5">
                  <div>
                    <img
                        alt="..."
                        className="img-fluid img-raised"
                        src={require("../../assets/img/dogs/venus/dock1.jpg")}
                    ></img>
                    <h4 className="title">Other</h4>
                    <p className="description">
                      Dock diving PB: 26ft 9in<br />
                      Fast CAT PB: 28.74mph<br />
                      Barn Hunt: Novice title
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="wrapper" 
              style={{
                backgroundColor: "#e8e8e8",
                padding: "50px 0 100px"
              }}>
        <Container>
          <h2 className="title text-center">Previous Litters</h2>
            <div className="team">
              <Row>
                <Col md="2" className="text-center"></Col>
                <Col md="4" className="text-center">
                  <a href="/litters/venus-hopsing-2022">
                    <h4>Venus X Hop Sing - 2022</h4>
                    <img
                      alt=""
                      className="img-raised"
                      src={require("../../assets/img/dogs/litters/vh2022/announcement.jpg")}
                    />
                  </a>
                </Col>
                <Col md="4" className="text-center">
                  <a href="/litters/venus-rip-2023">
                    <h4>Venus X Rip - 2023 </h4>
                    <img
                      alt=""
                      className="img-raised"
                      src={require("../../assets/img/dogs/litters/vr2023/announcement.jpg")}
                    />
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="section-dog">
          <div
              className="image-fullw section-gallery-bg"style={{
                padding: "100px 0"
              }}>
              <DogGallery images={images} />
            </div>
        </div>
    </>
  );
}

export default VenusPage;
