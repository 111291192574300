import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col, ModalBody, Modal, 
  Carousel, CarouselItem, CarouselIndicators
} from "reactstrap";

// core components
import MainNavbar from "../../components/Navbars/MainNavbar.js";
import LandingPageHeader from "../../components/Headers/LandingPageHeader.js";
import DefaultFooter from "../../components/Footers/DefaultFooter.js";
import DogGallery from "./DogGallery";

function NimonaPage() {
  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === clearances.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? clearances.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const images = [
    require("../../assets/img/dogs/nimona/reg1.jpg"),
    require("../../assets/img/dogs/nimona/reg2.jpg"),
    require("../../assets/img/dogs/nimona/reg3.jpg"),
    require("../../assets/img/dogs/nimona/reg4.jpg"),
    require("../../assets/img/dogs/nimona/reg5.jpg"),
    require("../../assets/img/dogs/nimona/reg6.jpg"),
    require("../../assets/img/dogs/nimona/reg7.jpg"),
    require("../../assets/img/dogs/nimona/reg8.jpg"),
    require("../../assets/img/dogs/nimona/reg9.jpg"),
    require("../../assets/img/dogs/nimona/reg10.jpg"),
    require("../../assets/img/dogs/nimona/reg11.jpg"),
    require("../../assets/img/dogs/nimona/reg12.jpg"),
    require("../../assets/img/dogs/nimona/reg13.jpg"),
    require("../../assets/img/dogs/nimona/reg14.jpg"),
    require("../../assets/img/dogs/nimona/reg15.jpg"),
    require("../../assets/img/dogs/nimona/reg16.jpg"),
    require("../../assets/img/dogs/nimona/reg17.jpg"),
    require("../../assets/img/dogs/nimona/reg18.jpg"),
    require("../../assets/img/dogs/nimona/reg19.jpg"),
    require("../../assets/img/dogs/nimona/reg20.jpg"),
    require("../../assets/img/dogs/nimona/reg21.jpg"),
    require("../../assets/img/dogs/nimona/reg22.jpg"),
    require("../../assets/img/dogs/nimona/reg23.jpg"),
    require("../../assets/img/dogs/nimona/reg24.jpg"),
  ];

  const clearances = [
    require("../../assets/img/dogs/nimona/clearance1.jpg"),
    require("../../assets/img/dogs/nimona/clearance2.jpg"),
    require("../../assets/img/dogs/nimona/clearance3.jpg"),
    require("../../assets/img/dogs/nimona/clearance5.jpg"),
    require("../../assets/img/dogs/nimona/clearance4.jpg"),
    require("../../assets/img/dogs/nimona/clearance4a.jpg"),
    require("../../assets/img/dogs/nimona/clearance6.jpg"),
    require("../../assets/img/dogs/nimona/clearance7.jpg"),
    require("../../assets/img/dogs/nimona/clearance8.jpg"),
    require("../../assets/img/dogs/nimona/clearance9.jpg"),
  ];
  return (
    <>
      <MainNavbar />
      <div className="wrapper">
        <LandingPageHeader bg="nimona" />
      </div>
      <div className="back-nav">
        <a href="../dogs"><i className="now-ui-icons arrows-1_minimal-left"></i> Back to Dogs</a>
      </div>
      <div className="wrapper">
        <div className="section section-story-overview section-dog">
          <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div className="name-desc">
                <h2 className="title">Nimona</h2>
                <h3 className="subtitle">Endgame's I'm a Shark FM RATN DE HDE AE</h3>
                <div>
                  Nimona is a black and white (with ghost tri) smooth coat border collie x jack russell mix who is from the Venus x HopSing litter. She was my pick of the litter and will stay with me to play dog sports. She had an unfortunate growth plate injury at 3 months which caused her radius and ulna on her right leg to grow at different rates. Her leg was operated on to prevent elbow damage but the legs remain different lengths. She performs great despite this but will not have elbow clearances due to the accident. 
                </div>
                <div>
                  <h4 className="subtitle">Personality</h4>
                  Nimona is very cuddly and affectionate in the house. She is the lap dog and claims her spot next to me every day. She loves other dogs and is very appropriate with her interactions with them. She is high drive, biddable, and high energy with a lovely off switch. She hasn't let her injury slow her down yet but does have avoidance to being grabbed by strangers since her surgery. She loves visitors at home and approaches most strangers willingly still outside the house so I expect her to recover from her past experiences with time. She works for other people in agility and will let strangers reward her with food or tugging during practice. I expect her to mature into a stranger neutral dog and not a Walmart greeter like her mother.
                </div>
                <div>
                  <h4 className="subtitle">Ability</h4>
                  Nim has surpassed all of my expectations post injury. She took to dock diving immediately and picked up playing flyball quite fast. She is a reliable teammate and rarely makes mistakes in the flyball lanes. She played disc competitively for a friend and has good control over her body when it comes to catching discs. Her agility training/trialing has been minimal since I opted to not put her leg through more stress from full time agility work, but I have seen beautiful jumping form and extension from her. She does not have high prey drive when it comes to cats, chickens, and smaller dogs, but she did take to barn hunt immediately without formal training.
                </div>
              </div>
            </Col>
            <Col className="ml-auto mr-auto align-middle" md="5">
              <div className="health-clearances">
                <Row>
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("../../assets/img/dogs/nimona/stack3.jpg")}
                  />
                  <div className="small">Born on 07/16/22. 15.75" tall. 21lbs.</div>
                </Row>

                <h3 className="title text-center">Health Clearances</h3>
                <Row>
                  <div className="sec-60">
                  <ul>
                    <li><span className="bold">Hips:</span> OFA Good*, Pennhip .41/.36</li>
                    <li><span className="bold">Elbows:</span> Unchecked</li>
                    <li><span className="bold">Shoulders:</span> OFA Normal</li>
                    <li><span className="bold">Eyes:</span> Clear (2022)</li>
                    <li><span className="bold">Knees:</span> OFA Normal</li>
                    <li><span className="bold">Hearing:</span> BAER Normal</li>
                    <li><span className="bold">Spine:</span> LTV free</li>
                  </ul>
                  * OFA Prelim
                  </div>
                  <div className="sec-40">

                  <div className="small">Border Collie:</div>
                  <ul>
                    <li><span className="bold">CEA</span> Clear</li>
                    <li><span className="bold">DM</span> Clear</li>
                    <li><span className="bold">SN</span> Clear</li>
                    <li><span className="bold">TNS</span> Clear</li>
                    <li><span className="bold">IGS</span> Clear</li>
                    <li><span className="bold">NCL5</span> Clear</li>
                    <li><span className="bold">G/G</span> Clear</li>
                    <li><span className="bold">DH</span> Clear</li>
                    <li><span className="bold">EOD</span> Clear</li>
                  </ul>
                   <div className="small">Jack Russell:</div>
                  <ul>
                    <li><span className="bold">SCID</span> Clear</li>
                    <li><span className="bold">PLL</span> Clear</li>
                    <li><span className="bold">CDDY/IVDD</span> Clear</li>
                    <li><span className="bold">CMS</span> Clear</li>
                    <li><span className="bold">SCA</span> Clear</li>
                    <li><span className="bold">ENAM</span> Clear</li>
                    <li><span className="bold">HUU</span> Clear</li>
                  </ul>
                   <div className="small">Other:</div>
                  <ul>
                    <li><span className="bold">DCM1</span> Carrier*</li>
                  </ul>
                </div>

                  * The DCM1 genetic test does not effect jack russells or border collies.
                </Row>
                <div className="text-center"><br/>
                  <a onClick={() => setModal1(true)} className="btn btn-round action-btn" type="button">
                    Pedigree
                  </a>
                  <a onClick={() => setModal2(true)} className="btn btn-round action-btn" type="button">
                    Health Clearances
                  </a>
                  <Modal isOpen={modal1} toggle={() => setModal1(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal1(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                      <h4 className="title title-up"></h4>
                    </div>
                    <ModalBody>
                      <img
                        alt="..."
                        src={require("../../assets/img/dogs/litters/vh2022/pedigree.jpg")}
                      ></img>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={modal2} toggle={() => setModal2(false)} style={{
                    maxWidth: "900px"
                  }}>
                    <div className="modal-header justify-content-center">
                      <button
                        className="close"
                        type="button"
                        onClick={() => setModal2(false)}
                      >
                        <i className="now-ui-icons ui-1_simple-remove"></i>
                      </button>
                    </div>
                    <ModalBody>
                      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                        <CarouselIndicators
                          items={clearances}
                          activeIndex={activeIndex}
                          onClickHandler={goToIndex}
                        />
                        {clearances.map(item => {
                          return (
                            <CarouselItem
                              onExiting={onExiting}
                              onExited={onExited}
                              key={item}
                              className="text-center"
                            >
                              <img src={item} alt="" className="img-small" />
                              <div className="carousel-caption d-none d-md-block">
                              </div>
                            </CarouselItem>
                          );
                        })}
                        <a
                          className="carousel-control-prev"
                          data-slide="prev"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            previous();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-left"></i>
                        </a>
                        <a
                          className="carousel-control-next"
                          data-slide="next"
                          href="#pablo"
                          onClick={e => {
                            e.preventDefault();
                            next();
                          }}
                          role="button"
                        >
                          <i className="now-ui-icons arrows-1_minimal-right"></i>
                        </a>
                      </Carousel>
                    </ModalBody>
                  </Modal>
                </div>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
      </div>

      <div className="section section-dog text-center">
        <div
          className="image-fullw section-sep-image"
          style={{
            backgroundImage: "url(" + require("../../assets/img/dogs/nimona/header2.jpg") + ")"
          }}
        ><div className="wrapper">

        </div>
        </div>
      </div>

      <div className="wrapper">
        <Container>
          <h2 className="title text-center">Accomplishments & Training</h2>
          <div className="team">
            <Row className="pb-5">
              <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid img-raised"
                    src={require("../../assets/img/dogs/nimona/awards1.jpg")}
                  ></img>
              </Col>
              <Col md="6">
                <h4 className="title first">Main sports</h4>
                <p>Nimona is currently focusing on agility, dock diving, disc, and flyball. She has already shown great potential in all four sports so I am excited to see where her future leads. She was promoted to the expert division in Toss and Fetch league her first season. Her first weekend of dock diving she set a personal best of 23ft 10in. She ran mostly 3.9s her first flyball tournament with a few 3.8s and has a current pb of 3.7.</p>
              </Col>
            </Row>
            <Row>
              <Col md="4" className="mb-5">
                <div className="">
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/nimona/disc1.jpg")}
                  ></img>
                  <h4 className="title">Disc</h4>
                  <p className="description">
                   2024 Skyhoundz Worlds Extreme Distance Micro Dogs - 2nd<br />
                   2024 Skyhoundz Worlds Classic Sport Micro Dog - 3rd<br /><br />
                   
                   Expert division T&F league<br />
                   T&F 90:5 Summer 2024: #1 out of 3,129<br />
                   T&F Champs 2024: #18/837 worldwide<br />
                   T&F Spring 2023: #109 out of 3,245<br />
                   T&F Champs 2023: #1 Expert regional, #57 worldwide<br /><br />

                   <strong>Personal bests</strong><br />
                   Throw and Go: 57<br />
                   Skyhounds Bullseye: 50<br />
                   Xtreme Distance Light: 191ft<br/>
                  </p>
                </div>
              </Col>
              <Col md="4" className="mb-5">
                <div>
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/nimona/dockdiving2.jpg")}
                  ></img>
                  <h4 className="title">Dock Diving</h4>
                  <p className="description">
                    Personal best: 26ft 5in<br />
                    AR Personal best: 17ft<br />
                    HD Personal best: 16.2<br /><br />

                    <strong>2024 NADD Season:</strong><br/>
                    1st Lap Elite Air Retrieve<br/>
                    2nd Lap Elite Distance<br/><br/>

                    <strong>2024 NADD Nationals:</strong><br/>
                    1st Lap Elite Hydrodash<br/>
                    2nd Lap Elite Air Retrieve<br/>
                    6th Lap Elite Distance<br/><br/>

                    <strong>2024 South East Regional:</strong><br/>
                    1st Lap Elite Distance<br/>
                    1st Lap Elite Air Retrieve<br/>
                    1st Lap Elite Hydrodash<br/><br/>
                    Dock dogs: #1 USA club lap dog 2023
                  </p>
                </div>
              </Col>
              <Col md="4" className="mb-5">
                <div>
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={require("../../assets/img/dogs/nimona/flyball3.jpg")}
                  ></img>
                  <h4 className="title">Other</h4>
                  <p className="description">
                    Flyball PB: 3.792 (Pack: 3.883)<br />
                    Average: 3.9<br /><br />
                    Barn hunt: Novice title<br /><br />
                    Agility: running in Novice
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <div className="wrapper" 
              style={{
                backgroundColor: "#e8e8e8",
                padding: "50px 0 100px"
              }}>
        <Container>
          <h2 className="title text-center">Upcoming Litters</h2>
            <div className="team">
              <Row>
                <Col md="4" className="text-center"></Col>
                <Col md="4" className="text-center">
                  <a href="/litters/nimona-jerome-2025">
                    <h4>Nimona X Jerome - 2025</h4>
                    <img
                      alt=""
                      className="img-raised"
                      src={require("../../assets/img/dogs/litters/nj2025/announcement.jpg")}
                    />
                  </a>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      <div className="section-dog">
        <div
            className="image-fullw section-gallery-bg"style={{
              padding: "100px 0"
            }}>
            <DogGallery images={images} />
          </div>
      </div>
    </>
  );
}

export default NimonaPage;
