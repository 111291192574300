import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MainNavbar from "components/Navbars/MainNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";

function AboutPage() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
      <>
        <MainNavbar />
        <div className="wrapper">
          <LandingPageHeader bg="header2" />
          <div className="section section-about-us">
            <Container>
              <div className="separator separator-primary"></div>
              <div className="section-story-overview">
                <Row className="text-center">
                  <Col md="4">
                    <img
                        className="rounded-circle img-raised"
                        src={require("assets/img/about3.jpg")}
                    />
                  </Col>
                  <Col md="1"></Col>
                  <Col md="7">
                    <h2>
                      Endgame Sport Dogs
                    </h2>
                    <p>
                      Adriana has been competing in dog sports since 2013 and actively participates in a variety of venues. She has titled dogs in agility, dock diving, barn hunt, rally, disc, lure coursing, and has trained in obedience and scentwork. Our goal is to demonstrate the ability of our dogs in a variety of different sports and breed healthy, tempermentally sound dogs that excel at in any venue. Endgame Sport Dogs is currently located near Raleigh, NC.
                    </p>
                    <p></p>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          <DefaultFooter />
        </div>
      </>
  );
}

export default AboutPage;
